import { useContext } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { ToasterContext } from "../../contexts";
import styled from "styled-components";
export const ToasterTextWrapper = styled.div`
  /* color: ${(props) => {
    return props.isError ? "#ff0000a1" : "white";
  }}; */

  width: 100%;
  .toast-header .btn-close: {
    color: white;
  }
`;

const SuccessToaster = () => {
  const {
    showToaster,
    setShowToaster,
    toasterText,
    isError,
    errorToasterText,
    successToasterText,
    setErrorToasterText,
    setSuccessToasterText,
  } = useContext(ToasterContext);

  return (
    <ToastContainer position="bottom-center" className="m-2 bg-dark bg-opacity-100  ">
      <Toast
        onClose={() => {
          setShowToaster(false);
          setSuccessToasterText("");
          setErrorToasterText("");
        }}
        show={showToaster}
        delay={6000}
        autohide
        className="bg-dark bg-opacity-100"
      >
        <Toast.Header
          closeVariant="white"
          className={`border-0 d-flex align-items-center ${isError ? "bg-dark" : "bg-success"} bg-opacity-100`}
        >
          <ToasterTextWrapper isError>
            <h6
              className={`me-auto ms-2 mb-0 ${
                isError ? "bg-dark text-danger" : "bg-success text-white"
              } bg-opacity-100 `}
            >
              {isError ? errorToasterText : successToasterText}
            </h6>
          </ToasterTextWrapper>
        </Toast.Header>
      </Toast>
    </ToastContainer>
  );
};

export default SuccessToaster;
