import styled from "styled-components";

export const InfoCardWrapper = styled.div`
  text-align: center;
  background-color: transparent;
  border-radius: 20px;
  color: white;
  &:hover {
    background-color: #292929ad;
  }
`;
const InfoCard = ({ label, value }) => {
  return (
    <InfoCardWrapper className="bg-opacity-25 bg-secondary">
      <p className="mb-1">{label}</p>
      <p>{value}</p>
    </InfoCardWrapper>
  );
};

export default InfoCard;
