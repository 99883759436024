import React, { useContext, useEffect, useState } from "react";
import { Col, Row, Container, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { InfoCard } from "./components/cards";
import ConnectModal from "./components/modals/ConnectModal";
import { ToasterContext } from "./contexts";
import { initMetaMask, initWalletConnect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";

export const StyledButton = styled.button`
  background-color: transparent;
  color: var(--secondary-text);
  width: 100%;
  cursor: pointer;
  border: 1px solid var(--secondary-text);
  border-radius: 8px;
  font-family: "Courier New";
  font-weight: 700;
  font-size: 3vw;
  overflow: hidden;
  line-height: 175%;
  text-align: center;
  letter-spacing: 0.1em;
  margin-bottom: 30px;
  max-width: fit-content;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  transition: 0.25s;
  &:hover {
    color: #f8e56d;
    border: 1px solid #f8e56d;
  }
  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    font-size: 2.2vw;
  }
  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    font-size: 2vw;
  }
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    font-size: 1.4vw;
  }
`;
export const StyledLinkButton = styled.button`
  background-color: #545454;
  color: black;
  width: 100%;
  cursor: pointer;
  border: 0px;
  border-radius: 8px;
  font-family: "Courier New";
  font-weight: 800;
  font-size: 2.5vw;
  overflow: hidden;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.1em;
  max-width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  transition: 0.25s;
  &:hover {
    color: #545454;
    background-color: black;
  }
  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    font-size: 2.2vw;
  }
  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    font-size: 1.5vw;
  }
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    font-size: 1vw;
  }
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const { setErrorToasterText, setSuccessToasterText } = useContext(ToasterContext);

  const [isConnectModalVisible, setIsConnectModalVisible] = useState(false);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    WEI_COST: 0,
  });

  const claimNFTs = async () => {
    let cost = CONFIG.WEI_COST;
    let totalCostWei = String(cost);
    setClaimingNft(true);

    let isWhiteListed = await blockchain.smartContract.methods.whitelisted(blockchain.account).call();
    if (isWhiteListed == 0) {
      setErrorToasterText("You are not Whitelisted");
      setClaimingNft(false);
    } else {
      blockchain.smartContract.methods
        .mint()
        .send({
          to: CONFIG.CONTRACT_ADDRESS,
          from: blockchain.account,
          value: totalCostWei,
        })
        .then((receipt) => {
          console.log(receipt);

          setSuccessToasterText(`Success, minted #${blockchain.id}`);
          setClaimingNft(false);
          dispatch(fetchData(blockchain.account));
        })
        .catch((err) => {
          console.log(err);
          setErrorToasterText("Sorry, something went wrong please try again later.");
          setClaimingNft(false);
        });
    }
  };

  const handleChange = async (walletOption) => {
    window.location.replace("#");
    if (walletOption === "metamask") {
      dispatch(initMetaMask());
    } else if (walletOption === "walletConnect") {
      dispatch(initWalletConnect());
    }
    getData();
  };

  useEffect(()=>{
    if (blockchain.errorMsg !== "") { setErrorToasterText(blockchain.errorMsg)}
    else if (blockchain.id == 0) { setErrorToasterText("You are not Whitelisted")}
  },[blockchain]);

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
      setIsConnectModalVisible(false);
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    // setErrorToasterText("Error something went wrong!");
    // setSuccessToasterText("Your message here");
    window.location.replace("#");
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <Container className="align-items-center d-flex flex-column justify-content-center vh-100">
          <s.Container ai={"center"} jc={"center"}>
            <s.SpacerSmall />
            <Container className="w-50">
              <Row className="mt-1">
                <Col xs="12" className="d-flex flex-column text-white text-center">
                  <span className="mb-1 font-monospace">exclusive store opening soon</span>
                </Col>
              </Row>
              <Row className="text-center mt-5 text-white">
                <Col xs="12">
                  <StyledLinkButton>
                  <a className="mb-0" href="https://opensea.io/collection/derschutze-nftees" style={{ color: 'inherit', textDecoration: 'none' }}>OpenSea</a>
                  </StyledLinkButton>
                </Col>
                <Col className=" mt-2" xs="12">
                  <StyledLinkButton>
                  <a className="mb-0" href="https://etherscan.io/address/0x7EF68ed82edd0b5feFC8AC82F822c5396Bd35BB1" style={{ color: 'inherit', textDecoration: 'none' }}>EtherScan</a>
                  </StyledLinkButton>
                </Col>
              </Row>
            </Container>
          </s.Container>
      </Container>
      <ConnectModal
        onClick={(walletName) => handleChange(walletName)}
        isVisible={isConnectModalVisible}
        onHide={() => setIsConnectModalVisible(false)}
      />
    </s.Screen>
  );
}

export default App;
