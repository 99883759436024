import React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { ConnectWalletCard } from "../cards/";
import metamask from "../../assets/images/metamask.svg";
import walletConnect from "../../assets/images/walletconnect.svg";

const ConnectModal = ({ isVisible, onHide, onClick }) => {
  return (
    <Modal centered show={isVisible} onHide={onHide}>
      <Modal.Body>
        <Row>
          <Col xs="12">
            <ConnectWalletCard
              onClick={() => onClick("metamask")}
              icon={metamask}
              name="MetaMask"
              description="Connect to your MetaMask Wallet"
            />
          </Col>
          <Col xs="12">
            <ConnectWalletCard
              onClick={() => onClick("walletConnect")}
              icon={walletConnect}
              name="WalletConnect"
              description="Scan with WalletConnect to connect"
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ConnectModal;
