import styled from "styled-components";

export const ConnectWalletCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  &:hover {
    background-color: #f7f7f7;
    border-radius: 20px;
  }
`;

const ConnectWalletCard = ({ name, icon, description, onClick }) => {
  return (
    <ConnectWalletCardWrapper onClick={onClick}>
      <img src={icon} width="80" height="80" />
      <h3>{name}</h3>
      <p className="text-muted">{description}</p>
    </ConnectWalletCardWrapper>
  );
};

export default ConnectWalletCard;
