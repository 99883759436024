import React, { useEffect, useState } from "react";

export const ToasterContext = React.createContext();

const ToasterProvider = (props) => {
  const [showToaster, setShowToaster] = useState(false);
  const [successToasterText, setSuccessToasterText] = useState("");
  const [errorToasterText, setErrorToasterText] = useState("");
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    setIsError(true);
    if (errorToasterText) {
      setShowToaster(true);
    }
  }, [errorToasterText]);
  useEffect(() => {
    setIsError(false);
    if (successToasterText) {
      setShowToaster(true);
    }
  }, [successToasterText]);
  return (
    <ToasterContext.Provider
      value={{
        showToaster,
        setShowToaster,
        errorToasterText,
        setErrorToasterText,
        successToasterText,
        setSuccessToasterText,
        isError,
      }}
    >
      {props.children}
    </ToasterContext.Provider>
  );
};

export default ToasterProvider;
